import React from "react"
import { Link } from "gatsby"
import Background from '../images/shop.jpg';

const ShopifyBanner = () => (
  <div className="row infinite-row banner align-middle" style={{ backgroundImage:`url(${Background})` }}>
    <div className="banner__content column small-12 medium-8 medium-offset-2 large-6 large-offset-3 text-center">
      <img src={'/shopify.svg' } alt="Shopify Partners" className="gm-bottom--2 width-100 shopify-logo" />
      <p className="gm-bottom--2">Shopify is a complete e-commerce solution for your business. It lets you organise your products, accept credit card payments, and track and respond to orders - all with a simple, beautiful and intuitive interface.</p>
      <Link to="/services/shopify-development-bournemouth" className="button">Read more</Link>
    </div>
  </div>
)

export default ShopifyBanner
