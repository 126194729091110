import PropTypes from "prop-types"
import React from "react"

const Spacer = ({ space }) => (
    <div className="row">
      <div className={ `column gm-bottom--${space}`}></div>
    </div>
)

Spacer.propTypes = {
  siteTitle: PropTypes.number,
}

Spacer.defaultProps = {
  space: `2`,
}

export default Spacer